import React from 'react';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

const HalfDoughnutChart = ({ data, style = {} }) => {
  const options = {
    rotation: -90,
    circumference: '180',
    cutout: '80%',
    hover: { mode: null },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: getColor('gray-500'),
        borderColor: getColor('gray-500'),
        borderWidth: 1,
        callbacks: {
          labelTextColor() {
            return getColor('black');
          },
          label: function (tooltipItem) {
            return ' ' + tooltipItem.label + ' ' + tooltipItem.raw + '%';
          }
        }
      }
    }
  };

  return (
    data && (
      <div style={style}>
        <Doughnut data={data} options={options} className="mb-n6" />
      </div>
    )
  );
};

HalfDoughnutChart.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object
};

export default HalfDoughnutChart;
